<template>
    <SimpleTable formclass="Expert/form" endpoint="experts" keepOpenFormOnSave="true" :aditionalColumns="headers" :hideNew="!isAdmin" :hideEdit="!isAdmin" hideDelete>
        <template v-slot="data">
            <h1>Especialistas</h1>
            <v-divider class="mb-4" />
            <v-layout wrap class="ma-12">
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ total(data.items) }}
                            </div>
                            Solicitações
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 sm6>
                    <v-card color="primary">
                        <v-card-text class="white--text">
                            <div class="headline mb-2">
                                <v-icon dark>functions</v-icon>
                                {{ active(data.items) }}
                            </div>
                            Cadastros aprovados
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </template>

        <template v-slot:extra-column="item">
            <v-row class="pt-1">
                <v-switch v-model="item.canDouble" class="my-0 py-0 mt-3 mr-4" :label="$t('Dobra')" color="success" hide-details
                    @change="changeCanDouble(item.id, item.canDouble)" />
                <v-chip v-if="item.status == 5" class="ma-2" color="success" outlined>
                    <v-icon left> done </v-icon>
                    Validado
                </v-chip>
                <v-chip v-else class="ma-2" outlined color="error"> <v-icon left> error </v-icon>Pendente</v-chip>
            </v-row>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
import { mapGetters } from "vuex";

export default {
    components: {
        SimpleTable,
    },

    data() {
        return {
            headers: [
                { text: this.$t("Status"), value: "extra-column", align: "center", sortable: false },
                { text: this.$t("Email"), value: "user", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
            isAdmin: false,
        };
    },

    mounted() {
        this.isAdmin = this.verifyRole("ADMIN");
    },

    computed: {
        ...mapGetters(["verifyRole"]),
    },

    methods: {
        total(items) {
            return Number.parseFloat(items.length).toFixed(0);
        },
        active(items) {
            return Number.parseFloat(items.reduce((sum, i) => (i.status == 5 ? sum + 1 : sum), 0)).toFixed(0);
        },
        changeCanDouble(id, newValue) {
            this.$http.patch(`experts/${id}/canDouble`, { data: { canDouble: newValue } }).catch(() => this.$eventHub.$emit("msgError", this.$t("Erro ao fazer checkin.")));
        },
    },
};
</script>